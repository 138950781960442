<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>

    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Duyurular</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="welcome-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="content-side col-lg-12 col-md-12 col-sm-12">
            <div v-if="Duyurular.length>0" class="content-inner">
              <div class="blog-posts">
                <div class="row clearfix">
                  <!--News Block-->

                  <div
                    v-for="(duyuruilan,index) in Duyurular"
                    :key="index"
                    class="news-block-five col-md-4 col-sm-12"
                  >
                    <div class="inner-box">
                      <div class="image-box">
                        <figure class="image">
                          <img
                            style="height: 200px; object-fit: cover"
                            :src="duyuruilan.kapak"
                            alt
                          />
                        </figure>

                        <div class="date">
                          <span>{{duyuruilan.duyruilanTarihi}}</span>
                        </div>

                        <div class="hover-box">
                          <div class="more-link">
                            <a @click="detayagit(duyuruilan.duyruilanId)">Görüntüle</a>
                          </div>
                        </div>
                      </div>

                      <div class="lower-box">
                        <div class="upper-info">
                          <h4>
                            <a @click="detayagit(duyuruilan.duyruilanId)">{{duyuruilan.duyruilanAdi}}</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--<div style="text-align: center;">
                <button @click="loadmore()" class="loadclass">Daha fazla</button>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import firebase from "firebase/app";
export default {
  name: "Duyurular",
  data() {
    return {
      loadkont: false,
      Duyurular: []
    };
  },

  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "DuyuruIlanlar");
    const duyurulist = this.$store.getters.getDuyuruIlanlar;
    this.Duyurular = Object.values(duyurulist);
    this.Duyurular.sort(function (b, a) {
      return a.duyruilanId - b.duyruilanId;
    });
      this.loadkont=true;
  },

  methods: {
    async loadmore() {
      const son = this.Duyurular[this.Duyurular.length-1];
      const ID=son.duyuruilanId;
      console.log(ID);
      await firebase
        .firestore()
        .collection("WebDuyuruIlan")
        .orderBy("giristarih","desc")
        .startAt(son)
        .limit(6)
        .get()
        .then(result => {
          result.forEach(doc => {
            const duyuruilan = doc.data();
            console.log('2'+duyuruilan.duyuruilanId);
            this.Duyurular.push(duyuruilan);
          });
        })
        .catch(err => {
          console.log("duyurufazlahata" + err);
        });
    },
    detayagit(ID){
      this.$router.push('/duyurudetay/'+ID);
    }
  }
};
</script>
<style>
.loadclass{
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;
    min-width: 180px;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 700;
    padding: 18px 30px 12px;
    background: var(--anacolor);
    border-radius: 0px;
    border-radius: 0px;
    overflow: hidden;
    font-family: 'Ubuntu', sans-serif;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    align-items: center;
}
.loadclass:hover{
    background: #222222;
    color: #ffffff;
}
</style>