<template>
  <div>
     <section id="social-media">
        <div class="container-fluid">
            <div class="row row-cols-md-8">
                <div class="col col-md-4 col-lg-4">
                    <ul>
                        <li>
                            <h4> İletişim Merkezi</h4>
                        </li>
                        <li>
                            <h1>Alo 153</h1>
                        </li>
                        <li>
                        </li>
                    </ul>
                    <div class="social">
                        <a target="_blank" :href="Belediyebilgiler.twit">
                            <svg id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"/></svg>
                        </a>
                        <a target="_blank" :href="Belediyebilgiler.face">
                            <svg id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"/><path d="m.396 7.977h4.976v16.023h-4.976z"/><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"/></svg>
                        </a>
                        <a target="_blank" :href="Belediyebilgiler.face">
                            <svg height="682pt" viewBox="-21 -117 682.66672 682" width="682pt" xmlns="http://www.w3.org/2000/svg"><path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"/></svg>
                        </a>
                    </div>
                </div>
                 <div class="col col-md-4 col-lg-4">
                    <router-link to="/"><img :src="Belediyebilgiler.kapak" alt=""/></router-link>
                    <div class="triangle"></div>
                </div>
                <div class="col col-md-4 col-lg-4">
                  <ul>
                    <li>
                      <h4>İLETİŞİM</h4>
                    </li>
                    <li>
                      <p>
                        {{ Belediyebilgiler.belediyeadresi }}
                      </p>
                    </li>
                    <li>{{ Belediyebilgiler.iletisim }}</li>
                    <li>{{ Belediyebilgiler.mail }}</li>
                  </ul>
                </div>
            </div>
            <div class="triangle"></div>
        </div>
      </section>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      Belediyebilgiler: {},
      Baskanbilgisi: {},
    };
  },
  methods: {
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
    },
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    await this.baskancek();
  },
};
</script>
<style>
#footerMenu ul li a {
  color: #fff;
  text-decoration: none;
}
#footerMenu ul li a:hover {
  color: #fff !important;
  border-bottom: 1px solid #fff;

  border-left: 15px solid #fff;

  padding-left: 10px;
  transition: all 0.4s ease;
}
</style>