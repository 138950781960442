<template>
  <div>
    <!-- SLİDER -->
      <div class="inclusive"
      :style="{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundImage:`url(${this.Belediyebilgiler.anaresim})`
      }"
      >
        <div class="slider">
            <div class="container-fluid">
                <div class="row">
                    <div class="col col-md-4 text">
                        <div class="questionnaire-area">
                            <h1>Güncel Haberler!</h1>
                            <h5>Yaşamdan geri kalmak istemiyorsan!</h5>
                            <router-link to="/haberler">
                            <button class="btn " type="submit">
                                Haberler
                                <svg enable-background="new 0 0 490.8 490.8" style="height: 10px;" version="1.1" viewBox="0 0 490.8 490.8" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m135.68 3.128c-4.237-4.093-10.99-3.975-15.083 0.262-3.992 4.134-3.992 10.687 0 14.82l227.12 227.14-227.14 227.12c-4.237 4.093-4.354 10.845-0.262 15.083 4.093 4.237 10.845 4.354 15.083 0.262 0.089-0.086 0.176-0.173 0.262-0.262l234.67-234.67c4.164-4.165 4.164-10.917 0-15.083l-234.65-234.67z" fill="#F44336"/><path d="m128.13 490.68c-5.891 0.011-10.675-4.757-10.686-10.648-5e-3 -2.84 1.123-5.565 3.134-7.571l227.14-227.12-227.14-227.11c-4.171-4.171-4.171-10.933 0-15.104s10.933-4.171 15.104 0l234.67 234.67c4.164 4.165 4.164 10.917 0 15.083l-234.67 234.67c-2 2.007-4.718 3.136-7.552 3.136z"/></svg>
                            </button>
                            </router-link>
                        </div>
                    </div>
                    <div class="col col-md-8 sliders">
                        <div class="swiper-container mySwiper">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide"
                                v-for="(haber, index) in Haberler.slice(0, 10)"
                                :key="index"
                                >
                                    <div class="topcard">
                                        <div class="card-section">
                                            Haberler
                                        </div>
                                    </div>
                                    <div class="img-area">
                                        <router-link to="/"><img :src="haber.kapak" alt=""/></router-link>
                                    </div>
                                    <div class="card-body">
                                        <div class="card-title">
                                            <h4>
                                              {{haber.haberAdi.slice(0,35)}}...
                                            </h4>
                                        </div>
                                        <div class="card-text">
                                            <ul>
                                                <li>
                                                  {{haber.haberAciklamasi.slice(0,150)}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- SLİDER -->

    <!-- SLIDER MENU -->
        <div class="anotherslider">
            <div class="row no-gutters">
                <div class="col col-md-5 col-lg-5 publicationstext">
                    <div class="publications-area">
                        <h2>
                        <span><svg enable-background="new 0 0 511.999 511.999" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m467.07 212.58-199.69-199.72c-11.777-11.748-30.828-11.609-42.451-0.015-11.689 11.719-11.689 30.747 0 42.451l199.72 199.7c11.7 11.7 30.721 11.7 42.422 0 11.732-11.732 11.732-30.692 0-42.422z"/><path d="m210.12 82.905-1.503 7.534c-10.587 52.958-36.317 103.27-71.118 144.05l108.65 108.65c40.772-34.794 90.39-61.215 143.35-71.815l7.548-1.503-186.92-186.91z"/><path d="m117.58 256.99-74.253 74.238c-17.545 17.545-17.618 46.029 0 63.647l42.422 42.422c17.545 17.545 46.029 17.617 63.647 0l74.246-74.246-106.06-106.06zm10.606 116.68c-5.859 5.859-15.352 5.859-21.211 0s-5.859-15.352 0-21.211l21.211-21.211c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211l-21.211 21.211z"/><path d="m286.27 445.28 20.405-20.405c17.619-17.616 17.561-46.1 1e-3 -63.631l-15.156-15.167c-8.377 5.627-16.377 11.741-24.155 18.265l18.1 18.127c5.845 5.815 5.886 15.279 0 21.196l-20.742 20.742-30.482-29.533-42.424 42.424 68.057 65.947c11.614 11.644 30.683 11.71 42.407-0.015 11.704-11.704 11.704-30.732 0-42.437l-16.011-15.513z"/><path d="m346.86 0c-8.291 0-15 6.709-15 15v30c0 8.291 6.709 15 15 15s15-6.709 15-15v-30c0-8.291-6.709-15-15-15z"/><path d="m466.86 120h-30c-8.291 0-15 6.709-15 15s6.709 15 15 15h30c8.291 0 15-6.709 15-15s-6.709-15-15-15z"/><path d="m447.47 34.394c-5.859-5.859-15.352-5.859-21.211 0l-30 30c-5.859 5.859-5.859 15.352 0 21.211s15.352 5.86 21.211 0l30-30c5.859-5.859 5.859-15.352 0-21.211z"/></svg></span>
                            <router-link to="/duyuruilanlar"> Duyurular</router-link>
                        </h2>
                    </div>
                </div>
                <div class="col col-md-7 col-lg-7 sliders-area">
                    <div class="swiper-container mySwiper">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide"
                          v-for="(duyuru, index) in Ilanlar.slice(0, 10)"
                          :key="index"
                          >
                            <div class="swiperarea">
                                <div class="icon mb-4">
                                  <svg enable-background="new 0 0 511.999 511.999" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m467.07 212.58-199.69-199.72c-11.777-11.748-30.828-11.609-42.451-0.015-11.689 11.719-11.689 30.747 0 42.451l199.72 199.7c11.7 11.7 30.721 11.7 42.422 0 11.732-11.732 11.732-30.692 0-42.422z"/><path d="m210.12 82.905-1.503 7.534c-10.587 52.958-36.317 103.27-71.118 144.05l108.65 108.65c40.772-34.794 90.39-61.215 143.35-71.815l7.548-1.503-186.92-186.91z"/><path d="m117.58 256.99-74.253 74.238c-17.545 17.545-17.618 46.029 0 63.647l42.422 42.422c17.545 17.545 46.029 17.617 63.647 0l74.246-74.246-106.06-106.06zm10.606 116.68c-5.859 5.859-15.352 5.859-21.211 0s-5.859-15.352 0-21.211l21.211-21.211c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211l-21.211 21.211z"/><path d="m286.27 445.28 20.405-20.405c17.619-17.616 17.561-46.1 1e-3 -63.631l-15.156-15.167c-8.377 5.627-16.377 11.741-24.155 18.265l18.1 18.127c5.845 5.815 5.886 15.279 0 21.196l-20.742 20.742-30.482-29.533-42.424 42.424 68.057 65.947c11.614 11.644 30.683 11.71 42.407-0.015 11.704-11.704 11.704-30.732 0-42.437l-16.011-15.513z"/><path d="m346.86 0c-8.291 0-15 6.709-15 15v30c0 8.291 6.709 15 15 15s15-6.709 15-15v-30c0-8.291-6.709-15-15-15z"/><path d="m466.86 120h-30c-8.291 0-15 6.709-15 15s6.709 15 15 15h30c8.291 0 15-6.709 15-15s-6.709-15-15-15z"/><path d="m447.47 34.394c-5.859-5.859-15.352-5.859-21.211 0l-30 30c-5.859 5.859-5.859 15.352 0 21.211s15.352 5.86 21.211 0l30-30c5.859-5.859 5.859-15.352 0-21.211z"/></svg> </div>
                                  <h3>{{duyuru.duyruilanAdi.slice(0,40)}}...</h3>
                                  <div class="nextarea">
                                      <a href="#">
                                        <svg enable-background="new 0 0 490.8 490.8" style="height: 10px;" version="1.1" viewBox="0 0 490.8 490.8" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m135.68 3.128c-4.237-4.093-10.99-3.975-15.083 0.262-3.992 4.134-3.992 10.687 0 14.82l227.12 227.14-227.14 227.12c-4.237 4.093-4.354 10.845-0.262 15.083 4.093 4.237 10.845 4.354 15.083 0.262 0.089-0.086 0.176-0.173 0.262-0.262l234.67-234.67c4.164-4.165 4.164-10.917 0-15.083l-234.65-234.67z" fill="#F44336"/><path d="m128.13 490.68c-5.891 0.011-10.675-4.757-10.686-10.648-5e-3 -2.84 1.123-5.565 3.134-7.571l227.14-227.12-227.14-227.11c-4.171-4.171-4.171-10.933 0-15.104s10.933-4.171 15.104 0l234.67 234.67c4.164 4.165 4.164 10.917 0 15.083l-234.67 234.67c-2 2.007-4.718 3.136-7.552 3.136z"/></svg>
                                        <svg enable-background="new 0 0 490.8 490.8" style="height: 10px;" version="1.1" viewBox="0 0 490.8 490.8" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m135.68 3.128c-4.237-4.093-10.99-3.975-15.083 0.262-3.992 4.134-3.992 10.687 0 14.82l227.12 227.14-227.14 227.12c-4.237 4.093-4.354 10.845-0.262 15.083 4.093 4.237 10.845 4.354 15.083 0.262 0.089-0.086 0.176-0.173 0.262-0.262l234.67-234.67c4.164-4.165 4.164-10.917 0-15.083l-234.65-234.67z" fill="#F44336"/><path d="m128.13 490.68c-5.891 0.011-10.675-4.757-10.686-10.648-5e-3 -2.84 1.123-5.565 3.134-7.571l227.14-227.12-227.14-227.11c-4.171-4.171-4.171-10.933 0-15.104s10.933-4.171 15.104 0l234.67 234.67c4.164 4.165 4.164 10.917 0 15.083l-234.67 234.67c-2 2.007-4.718 3.136-7.552 3.136z"/></svg>
                                      </a>
                                  </div>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
            </div>
    </div>
    <!-- SLIDER MENU -->

    <!-- Fast Menu -->
        <section class="president">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7 col-lg-7 fastmenu">
                    <div class="fastmenutext mb-4">
                        <h5>{{Belediyebilgiler.belediyeadi }}</h5>
                        <h1>Projelerimiz</h1>
                    </div>
                    
                    <div class="swiper-container mySwipers">
                      <div class="swiper-wrapper slide-wrapper">
                        <a href="#" class="swiper-slide"
                        v-for="(projeler, index) in Projeler.slice(0, 10)"
                          :key="index"
                                :style="{
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'top center',
                                  backgroundImage:`url(${projeler.kapak})`
                                }"
                        >
                          <div class="menuarena">
                               <h5>{{projeler.projeAdi.slice(0,30)}}...</h5>
                          </div>
                        </a>
                      </div>
                    </div>
                </div>
                <div class="col-md-5 col-lg-5 d-flex flex-nowrap presidentmain">
                    <div class="president-text">
                        <h4>{{Belediyebilgiler.belediyeadi }}</h4>
                        <strong>Değerli Hemşerilerim,</strong>
                        <p>
                            {{BaskanBilgisi.mesaj.slice(0,395)}}

                    
                        </p>
                        <span>
                            <h3>{{Belediyebilgiler.belediyeadi}} Belediye Başkanı</h3>
                            <p>{{BaskanBilgisi.baskanadi}}</p>
                        </span>
                        <div class="social">
                            <a href="#">
                                <svg enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">	
                                    <path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160    C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48    h192c61.76,0,112,50.24,112,112V352z"/>
                                    <path d="m256 128c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z"/>
                                    <circle cx="393.6" cy="118.4" r="17.056"/>
                                </svg>
                            </a>
                            <a href="#">
                                <svg width="512" height="512" enable-background="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"/>
                                </svg>
                            </a>
                            <a href="#">
                                <svg id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"/></svg>
                            </a>
                            <a href="#">
                                <svg height="682pt" viewBox="-21 -117 682.66672 682" width="682pt" xmlns="http://www.w3.org/2000/svg"><path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"/></svg>
                            </a>
                          </div>
                    </div>
                    <div class="presidentimg d-flex align-items-end">
                        <router-link to="/"><img :src="BaskanBilgisi.kapak" alt=""/></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Fast Menu -->

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      urll: "",
      Haberler: [],
      Slides: [],
      Ilanlar: [],
      Kararlar: [],
      Yerler: [],
      Baskanbilgisi: {},
      Projeler: [],
      Cralanlar: [],
      loadkont: false,
      kont: false,
      domAD: "",
      Belediyebilgiler: {},
    };
  },
  components: {},
  // linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),
  //background-repeat: round; background-attachment: fixed;
  async created() {
    const domainAd = window.location.hostname;
    this.domAD = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.belediyecek();
    await this.haberlercek();
    await this.$store.dispatch("vericekme", "Genel");
    await this.$store.dispatch("vericekme", "Slides");
    this.Slides = this.$store.getters.getSlides;
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.urll = `url(${this.Belediyebilgiler.kapak})`;
    this.loadkont = true;
    await this.baskancek();
    await this.duyuruilancek();
    await this.kararlarcek();
    await this.yerlercek();
    await this.projelercek();
    this.scriptekleslide();
    await this.Cralanlarcek();
     console.log(this.Belediyebilgiler);
  },
  methods: {
    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
    projedetayagit(ID) {
      this.$router.push("/projedetay/" + ID);
    },
    yerdetayagit(ID) {
      this.$router.push("/kentrehberidetay/" + ID);
    },
    cralanlardetayagit(ID) {
      this.$roter.push("/cralandetay/" + ID);
    },
    duyurudetayagit(ID) {
      this.$router.push("/duyurudetay/" + ID);
    },
    async haberlercek() {
      await this.$store.dispatch("vericekme", "Haberler");
      const habermap = this.$store.getters.getHaberler;
      this.Haberler = Object.values(habermap);
      this.Haberler.sort(function (b, a) {
        return a.haberId - b.haberId;
      });
      this.kont = true;
    },
    async belediyecek() {},
    async scriptekleslide() {
      // SCRİPT
      // let customscript = await document.createElement("script");
      // customscript.setAttribute("src", "js/custom-script.js");
      // document.head.appendChild(customscript);
      // // SCRİPT
      // let customscript2 = await document.createElement("script");
      // customscript2.setAttribute("src", "js/main.js");
      // document.head.appendChild(customscript2);
      // SCRİPT
      // let customscript3 = await document.createElement("script");
      // customscript3.setAttribute("src", "js/plugins.js");
      // document.head.appendChild(customscript3);
      // // SCRİPT
      // let customscript4 = await document.createElement("script");
      // customscript4.setAttribute("src", "js/jquery-2.1.1.min.js");
      // document.head.appendChild(customscript4);
      // SCRİPT
      let customscript5 = await document.createElement("script");
      customscript5.setAttribute("src", "js/script.js");
      document.head.appendChild(customscript5);
      let customscript6 = await document.createElement("script");
      customscript6.setAttribute("src", "assets/owl-carousel/owl.carousel.min.js");
      document.head.appendChild(customscript6);
    },
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.BaskanBilgisi = this.$store.getters.getBaskan;
    },
    async duyuruilancek() {
      await this.$store.dispatch("vericekme", "DuyuruIlanlar");
      const dilanlar = this.$store.getters.getDuyuruIlanlar;
      this.Ilanlar = Object.values(dilanlar);
      this.Ilanlar.sort(function (b, a) {
        return a.duyruilanId - b.duyruilanId;
      });
    },
    async kararlarcek() {
      await this.$store.dispatch("vericekme", "Kararlar");
      const kararlar1 = this.$store.getters.getKararlar;
      this.Kararlar = Object.values(kararlar1);
      this.Kararlar.sort(function (b, a) {
        return a.kararId - b.kararId;
      });
    },
    async yerlercek() {
      await this.$store.dispatch("vericekme", "Yerler");
      const yerler1 = this.$store.getters.getYerler;
      this.Yerler = Object.values(yerler1);
      this.Yerler.sort(function (b, a) {
        return a.yerId - b.yerId;
      });
    },
    async projelercek() {
      await this.$store.dispatch("vericekme", "Projeler");
      const projeler1 = this.$store.getters.getProjeler;
      this.Projeler = Object.values(projeler1);
      this.Projeler.sort(function (b, a) {
        return a.projeId - b.projeId;
      });
    },
    async Cralanlarcek() {
      await this.$store.dispatch("vericekme", "Cralanlar");
      const Cralanlar1 = this.$store.getters.getCralanlar;
      this.Cralanlar = Object.values(Cralanlar1);
      this.Cralanlar.sort(function (b,a) {
        return a.cralanlarId - b.cralanlarId;
      });
    }
  },
  computed: {
    teststyle() {
      return {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9))," +
          this.urll,
        "background-repeat": "round",
        "background-attachment": "fixed",
      };
    },
  },
  mounted() {
    if (window.location.hostname == "kaymakli.zeplinx.com") {
      let twitscrpt = document.createElement("script");
      twitscrpt.setAttribute("src", "https://platform.twitter.com/widgets.js");
      document.head.appendChild(twitscrpt);
    }
  },
};
</script>
<style>
#videoContainment {
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  overflow: hidden;
}
</style>
