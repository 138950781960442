<template>
  <div>
    <div class="inclusive">
        <header class="header ">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <nav class="navbar navbar-expand-lg navbar-light">
                            <div class="container-fluid">
                              <a class="navbar-brand">
                                  <router-link to="/"><img :src="Belediyebilgiler.kapak" alt=""/></router-link>
                              </a>
                              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                              </button>
                              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                  <li class="nav-item">
                                      <router-link class="nav-link active" aria-current="page" to="/"> Anasayfa</router-link>
                                  </li>
                                  <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      Başkanımız
                                    </a>
                                    <ul class="dropdown-menu big-menu" aria-labelledby="navbarDropdown">
                                      <div class="row">
                                        <div class="column">
                                          <h3>BAŞKAN</h3>
                                           <router-link to="/"><img :src="Baskanbilgisi.kapak" alt=""/></router-link>
                                        </div>
                                        <div class="column">
                                          <h3>Category 2</h3>
                                          <router-link to="/ozgecmis"> Başkan Özgeçmiş</router-link>
                                          <router-link to="/mesaj"> Başkan Mesaj</router-link>
                                          <router-link to="/baskangaleri"> Başkan Galeri</router-link>
                                          <router-link to="/baskanasor"> Banşkan'a Sor</router-link>
                                          <router-link to="/eskibaskanlar"> Belediye Başkanlarımız</router-link>
                                        </div>
                                      </div>
                                     </ul>
                                  </li>
                                  <li class="nav-item dropdown">
                                      <router-link class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" to="/birimler"> Kurumsal</router-link>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                      <li><router-link class="dropdown-item" to="/birimler"> Birimler</router-link></li>
                                      <li><router-link class="dropdown-item" to="/meclisuyeleri">Meclis Üyeleri</router-link></li>
                                      <li><router-link class="dropdown-item" to="/encumenlarimiz">Encümenlerimiz</router-link></li>
                                      <li><router-link class="dropdown-item" to="/kararlar">Meclis Kararları</router-link></li>
                                      <li><router-link class="dropdown-item" to="/raporlar">Faaliyet Raporları</router-link></li>
                                      <li><router-link class="dropdown-item" to="/raporlar">Meclis Gündemleri</router-link></li>
                                      <li><router-link class="dropdown-item" to="/tarihce">Etik Komisyonu</router-link></li>
                                      <li><router-link class="dropdown-item" to="/kvkk">KVKK Aydınlatma Metni</router-link></li>
                                    </ul>
                                  </li>
                                  <li class="nav-item dropdown">
                                     <router-link class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" to="/haberler"> Güncel </router-link>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                      <li><router-link class="dropdown-item" to="/haberler"> Haberler </router-link></li>
                                      <li><router-link class="dropdown-item" to="/projeler"> Projeler </router-link></li>
                                      <li><router-link class="dropdown-item" to="/duyuruilanlar">Duyurular</router-link></li>
                                    </ul>
                                  </li>
                                  <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      Hizmetlerimizi
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                      <li><a class="dropdown-item" :href="Belediyebilgiler.ebelediye">E-Belediye</a></li>
                                      <li><a class="dropdown-item" :href="Belediyebilgiler.nobetciecz">Nöbetçi Eczaneler</a></li>
                                      <li><router-link class="dropdown-item" to="/belgeler">Belge Örnekleri</router-link></li>
                                      <li><a class="dropdown-item" href="http://web.karaman.bel.tr:571/Mebis/VefatListesi.aspx">Vefat Edenler</a></li>
                                      <li><router-link class="dropdown-item" to="/evlenenler">Nikah Duyuruları</router-link></li>
                                    </ul>
                                  </li>
                                  <li class="nav-item">
                                    <router-link class="nav-link active" to="/iletisim"> İletişim</router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </nav>
                    </div>
                </div>
            </div>
        </header>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      Belediyebilgiler: {},
      Baskanbilgisi: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
      await this.$store.dispatch("vericekme", "Baskan");
    this.Baskanbilgisi = this.$store.getters.getBaskan;
  },
};
</script>

<style>
.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #217c3d;
}
</style>