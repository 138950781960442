<template>
  <div>
    <!--Page Title-->

    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>İletişim</h1>
          </div>
        </div>
      </div>
    </section>

    <!--Contact Section-->

    <section class="contact-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="left-col col-lg-6 col-md-12 col-sm-12">
            <div class="inner-box">
              <div class="images clearfix">
                <figure class="image">
                  <img :src="Belediyebilgiler.anaresim" alt />
                </figure>

                <figure class="image">
                  <img :src="Belediyebilgiler.kapak" alt />
                </figure>
              </div>

              <div class="contact-info-box">
                <div class="inner">
                  <ul class="info">
                    <li class="clearfix">
                      <h4>Bizi Arayın</h4>

                      <div class="content">
                        <span class="icon flaticon-telephone-2"></span>

                        <span>Ara</span>
                        <br />

                        <a
                          class="txt"
                          :href="'tel:' + Belediyebilgiler.iletisim"
                          >{{ Belediyebilgiler.iletisim }}</a
                        >
                        <br />
                        <span>Fax</span>
                        <br />

                        <a class="txt">{{ Belediyebilgiler.faxnumarasi }}</a>
                      </div>
                    </li>

                    <li class="clearfix">
                      <h4>Email adresimiz</h4>

                      <div class="content">
                        <span class="icon flaticon-postcard"></span>

                        <span>Mail Gönder</span>
                        <br />

                        <a
                          :href="{ mailto: Belediyebilgiler.mail }"
                          class="txt"
                          >{{ Belediyebilgiler.mail }}</a
                        >
                      </div>
                    </li>

                    <li class="clearfix">
                      <h4>Bizi Ziyaret Edin</h4>

                      <div class="content">
                        <span class="icon flaticon-map"></span>

                        <span class="txt">
                          {{ Belediyebilgiler.belediyeadresi }}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="right-col col-lg-6 col-md-12 col-sm-12">
            <div class="inner">
              <div class="sec-title with-separator">
                <h2>Sizin İçin Buradayız</h2>

                <div class="separator">
                  <span class="cir c-1"></span>
                  <span class="cir c-2"></span>
                  <span class="cir c-3"></span>
                </div>

                <div class="lower-text">
                  Bize Ulaşmak İçin Lütfen Formu Doldurunuz!.
                </div>
              </div>
              <form @submit.prevent="yolla">
                <div class="default-form form-box">
                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        v-model="adii"
                        type="text"
                        name="username"
                        placeholder="Ad Soyad"
                        required="required"
                        value
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        v-model="gidecekmail"
                        type="email"
                        name="email"
                        placeholder="E-Posta"
                        required="required"
                        value
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        v-model="gidecekPhone"
                        type="text"
                        name="phone"
                        placeholder="Telefon Numaranız"
                        required="required"
                        value
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <textarea
                        v-model="mesaj"
                        name="message"
                        placeholder="Mesajınız..."
                        required="required"
                      ></textarea>
                    </div>
                  </div>
                  <div
                    v-if="mailKontrol"
                    class="alert alert-success"
                    role="alert"
                  >
                    Mesajınız gönderildi. Teşekkür Ederiz!
                  </div>

                  <button v-if="!mailKontrol" class="theme-btn btn-style-one">
                    <span class="btn-title">Gönder</span>
                  </button>
                  <button
                    v-else
                    class="theme-btn btn-style-one"
                    disabled="disabled"
                  >
                    <span class="btn-title">Gönder</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Map Section-->

    <section class="map-section">
      <!--Map Box-->

      <div v-html="Belediyebilgiler.haritaIframe" class="map-box"></div>
    </section>
  </div>
</template>
<script>
import firebase from "firebase/app";
export default {
  name: "Iletisim",
  data() {
    return {
      adii: "",
      gidecekmail: "",
      gidecekPhone: "",
      mesaj: "",
      Belediyebilgiler: {},
      mailKontrol: false,
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
  methods: {
    async yolla() {
      const yeniIDD = Date.now().toString();
      await firebase.firestore().collection("Mailler").doc(yeniIDD).set({
        adi: this.adii,
        gidecekmail: this.gidecekmail,
        gidecekPhone: this.gidecekPhone,
        mesaj: this.mesaj,
        domainId: this.$store.getters.getDomainId,
      });
      this.mailKontrol = true;
    },
  },
};
</script>