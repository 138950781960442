<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <p>Yükleniyor</p>
    </div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Şehitlerimiz</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="history-section">
      <div class="pattern-layer" style="background-image: url(images/background/pattern-2.png);"></div>

      <div class="auto-container">
        <div class="history-container">
          <div class="center-line">
            <span class="dot upper-dot"></span>
            <span class="dot lower-dot"></span>
          </div>

          <div v-if="Sehitler.length>0" class="history-content">
            <!--Block-->

            <div v-for="(sehit,index) in Sehitler" :key="index">
              <div class="history-block">
                <div v-if="index %2 == 0" class="row clearfix">
                  <div class="image-col col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                      <div class="year-box">
                        <div class="year-inner">
                          <span>{{sehit.sehitTarih}}</span>
                        </div>
                      </div>

                      <div class="image-box">
                        <figure class="image">
                          <img :src="sehit.kapak" alt />
                        </figure>
                      </div>
                    </div>
                  </div>

                  <div class="text-col col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                      <h6>{{sehit.sehitYer}}</h6>

                      <h3>
                        <a>{{sehit.sehitAdi}}</a>
                      </h3>

                      <div class="text">{{sehit.sehitBilgi}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="Math.abs(index % 2) == 1" class="history-block alternate">
                <div class="row clearfix">
                  <div class="image-col col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                      <div class="year-box">
                        <div class="year-inner">
                          <span>{{sehit.sehitTarih}}</span>
                        </div>
                      </div>

                      <div class="image-box">
                        <figure class="image">
                          <img :src="sehit.kapak" alt />
                        </figure>
                      </div>
                    </div>
                  </div>

                  <div class="text-col col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                      <h6>{{sehit.sehitYer}}</h6>

                      <h3>
                        <a>{{sehit.sehitAdi}}</a>
                      </h3>

                      <div class="text">{{sehit.sehitBilgi}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--<div style="text-align: center;">
                <button @click="dahafazla()" class="loadclass">Daha fazla</button>
              </div>-->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Sehitler",
  data() {
    return {
      Sehitler: [],
      loadkont:false
    };
  },
  methods: {
    async dahafazla() {       
    }
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Sehitler");
    const sehitlist = this.$store.getters.getSehitler;
    this.Sehitler = Object.values(sehitlist);
    this.Sehitler.sort(function (b, a) {
      return a.sehitId - b.sehitId;
    });
       this.loadkont=true;
  }
};
</script>
<style>
.loadclass{
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;
    min-width: 180px;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 700;
    padding: 18px 30px 12px;
    background: var(--anacolor);
    border-radius: 0px;
    border-radius: 0px;
    overflow: hidden;
    font-family: 'Ubuntu', sans-serif;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    align-items: center;
}
.loadclass:hover{
    background: #222222;
    color: #ffffff;
}
</style>