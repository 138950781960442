<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section v-if="domAd == 'localhost'" class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>{{ Belediyebilgisi.belediyeadi }} Etik Komisyonu</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div style="white-space: pre-line">
                    <h2>Kişisel Verilerin İşlenmesi Genel Aydınlatma Metni</h2>
                    <h3>Veri Sorumlusunun Kimliği</h3>

                    <h5>
                      Biz, Nurhak Belediyesi olarak siz vatandaşlarımızın
                      kişisel verilerinizin güvenliğini oldukça önemsiyor ve
                      mahremiyetinize saygı duyuyoruz. Bu itibarla tüm kişisel
                      verilerinizin, 6698 sayılı Kişisel Verilerin Korunması
                      Kanunu (“Kanun”) kapsamında, hangi koşullarda elde
                      edildiğini, nasıl işlendiğini, korumak konusundaki
                      hassasiyetimizi ve bu kişisel bilgileriniz ile ilgili
                      haklarınızı aşağıda bilginize sunuyoruz. Tanımlar
                    </h5>

                    <h5>İşbu aydınlatma metninde geçen;</h5>

                    <h5>
                      Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek
                      kişiye ilişkin her türlü bilgiyi, İlgili Kişi: Kişisel
                      verisi işlenen gerçek kişi Kişisel Verilerin Korunması
                      Kanunu (“KVKK”): 7 Nisan 2016 tarihinde Resmi Gazete’de
                      yayınlanarak yürürlüğe giren 6698 sayılı Kişisel Verilerin
                      Korunması Kanunu’nu, Veri İşleyen: Veri sorumlusunun
                      verdiği yetkiye dayanarak onun adına Kişisel Verileri
                      işleyen gerçek veya tüzel kişiyi, Veri Sorumlusu: Kişisel
                      Verilerin işleme amaçlarını ve vasıtalarını belirleyen,
                      veri kayıt sisteminin kurulmasından ve yönetilmesinden
                      sorumlu olan gerçek veya tüzel kişiyi, ifade eder.
                    </h5>

                    <h5>
                      Kişisel verilerin işlenmesi ise bu verilerin elde
                      edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi,
                      değiştirilmesi, yeniden düzenlenmesi, açıklanması,
                      aktarılması, devralınması, elde edilebilir hale
                      getirilmesi, sınıflandırılması ya da kullanılmasının
                      engellenmesi gibi veriler üzerinde gerçekleştirilen her
                      türlü işlemi ifade eder. 6698 sayılı kanun gereği,
                      kimliğinizi belirli veya belirlenebilir kılan her türlü
                      bilginiz, “Kişisel Veri” olarak yukardaki tanım
                      kapsamında, Veri Sorumlusu sıfatıyla Nurhak Belediyesi
                      tarafından işlenebilecektir.
                    </h5>

                    <h4>
                      Kişisel verileriniz aşağıda izah etmiş olduğumuz şekilde
                      ve resmi mevzuat tarafından belirlenen sınırlar
                      çerçevesinde işlemekte, kaydedilmekte, aktarılmakta,
                      paylaşılmakta ve saklanmaktadır.
                    </h4>
                    Kişisel Verilerinizin Toplanması

                    <h5>
                      Kişisel Verilerin Toplanmasında Yöntemler ve Hukuki
                      Sebepler;
                    </h5>
                    <p>1982 Türkiye Cumhuriyeti Anayasası,</p>
                    <p>5237 Sayılı Türk Ceza Kanunu,</p>
                    <p>5393 Saylı Belediye Kanunu,</p>
                    <p>2464 Sayılı Belediye Gelirleri Kanunu,</p>
                    <p>1319 Emlak Vergisi Kanunu,</p>
                    <p>213 Sayılı Vergi Usulü Kanunu,</p>
                    <p>4737 Sayılı Kamu İhale Kanunu,</p>
                    <p>4857 Sayılı İş Kanunu,</p>
                    <p>657 Sayılı Devlet Memurları Kanunu,</p>
                    <p>
                      3071 Sayılı Dilekçe Hakkının Kullanılmasına Dair Kanun,
                    </p>
                    <p>4982 Sayılı Bilgi Edinme Hakkı Kanunu,</p>
                    <p>3194 Sayılı İmar Kanunu,</p>
                    <p>
                      3294 Sayılı Sosyal Yardımlaşma ve Dayanışmayı Teşvik
                      Kanunu,
                    </p>
                    <p>5236 Sayılı Kabahatler Kanunu,</p>
                    <p>
                      İlgili kanunlar başta olmak üzere Belediyelerin tabi
                      olduğu ilgili yönetmelik ve mevzuat uygulanmaktadır.
                    </p>
                    <h4>Kişisel verileriniz;</h4>
                    <h5>
                      Hizmetlerimiz ile ilgili bilgi almak amacıyla
                      çalışanlarımız veya tedarikçilerimize, sözlü, yazılı,
                      elektronik olarak veya matbu formlar ile ilettiğiniz
                      bilgiler aracılığıyla, Nurhak Belediyesi tarafından
                      sunulan hizmetlerin yürütülmesi ile ilgili faaliyetlerimiz
                      çerçevesinde, sözlü, yazılı, şahsen, web sitemizdeki form
                      veya uygulamalar vasıtası ile talep etmiş olduğumuz veya
                      Nurhak Belediyesi’ne talep ettiğiniz hizmetler dolayısı
                      ile şahsınız tarafından iletilen bilgiler aracılığıyla,
                      Nurhak Belediyesi ile ticari ilişki kurmak, teklif vermek
                      / istemek gibi amaçlarla fiziki veya sanal bir ortamda,
                      yüz yüze ya da mesafeli, sözlü veya yazılı ya da
                      elektronik olarak verilen kartvizit ve doldurulan bilgi
                      formları aracılığıyla, İş başvurusu yapmak amaçlı, İş kur
                      üzerinden veya şahsen, elektronik ortamda veya şahsen
                      iletilen özgeçmişler aracılığıyla Açılan ihaleler
                      esnasında, ilgili kanun ve yönetmelikler kapsamında,
                      tarafımıza iletilen tedarikçi çalışanlarına ait kişisel
                      veriler aracılığıyla, Etüt merkezi, müzik akademisi, kreş,
                      spor okulu vb gibi devam eden veya açılacak olan eğitim ve
                      kurs hizmetlerine ön kayıt / kayıt olmak amaçlı tarafımıza
                      ilettiğiniz bilgi ve belgeler aracılığıyla, Sinema,
                      tiyatro, konser, spor müsabakaları, kitap dağıtımı vb.
                      kültür-sanat ve sportif faaliyetlere katılım için
                      doldurmuş olduğunuz, formlar aracılığı ile, Sosyal
                      yardımlaşma ve dayanışma hizmetleri kapsamında, Basın ve
                      yayın birimimiz tarafından, etkinlik, özel gün,
                      başkanımızın ziyaretleri, sokak röportajları, sosyal
                      hizmet kapsamında gerçekleştirilen faaliyetlerden elde
                      edilen her türlü fotoğraf ve video aracılığıyla,
                      Tarafımıza iletmiş olduğunuz şikayet, istek ve öneriler
                      aracılığıyla, Nurhak Belediyesi hizmet binalarının içinde
                      ve çevresinde ayrıca hizmet noktalarındaki güvenlik
                      kameraları ile, Web sitemizi ziyaretleriniz sırasında
                      çerezler vasıtasıyla edindiğimiz IP adresleriniz ile,
                      toplanmaktadır.
                    </h5>

                    <h4>Kişisel Verilerinizin İşlenmesi</h4>

                    <h4>
                      Kişisel verileriniz, aşağıdaki sebeplerle işlenmektedir
                    </h4>

                    <h5>
                      Teknolojik anlamda hizmet süreçlerinin devamı için, tüm
                      ilgili kişi verilerinin, kurumsal kaynak planlama
                      yazılımına (ERP) kaydedilmesi ve hizmet ile sınırlı olarak
                      işlenmesi, İlgili kamu kurum ve kuruluşlarına,
                      yürürlükteki kanun ve mevzuatlar gereği, yasal
                      yükümlülükleri yerine getirmek amaçlı gerekli
                      bildirimlerin yapılması, Çalışan adayı başvuru
                      değerlendirmelerinin ve istihdamının yapılması, istihdamı
                      yapılmış personelin (çalışan, stajyer, çırak vb.) iş
                      süreçlerinin devamı, Tarafımıza iletmiş olduğunuz şikayet,
                      istek ve önerilerinizin değerlendirilerek
                      sonuçlandırılması, Yürürlükteki kanun ve mevzuattan doğan
                      haklarınızın tesisini sağlamak, Yasa dışı ve suç unsuru
                      oluşturabilecek faaliyetleri engellemek, Vatandaş
                      memnuniyetini ölçmek amaçlı, çeşitli yöntemlerle
                      araştırmalar gerçekleştirmek, Nurhak Belediyesi hizmet
                      binaları içinde ve çevresinde, fiziksel mekan güvenliği,
                      kamu güvenliği ve iş güvenliğini sağlayabilmek için,
                      Gerçekleştirdiğimiz hizmetler kapsamında gerekli olması
                      durumunda tedarik ve taşeronluk hizmetleri için, Yukarıda
                      belirtilen yollarla elde edilen görüntülerin, belediyemize
                      ait sosyal medya hesaplarında, web sitemiz sitemizde veya
                      yazılı veya görüntülü olarak, basın ve yayın
                      kuruluşlarında, duyuru ve bilgilendirme amaçlı, Nikah,
                      cenaze işlemleri, sağlık hizmetleri, yaşlı bakımı vb.
                      belediyecilik faaliyetlerini yürütebilmek, Kreş, kurslar,
                      etüt merkezleri vb. hizmetlerinin yürütülmesi amaçlı
                      gerçekleştirilmesi için gerekli olan iletişim
                      faaliyetlerinin yerine getirilmesi, İlgili kanun ve
                      yönetmelikler gereği yapılması gerekebilecek hukuki
                      işlemlerin gerçekleştirilmesi için, Web sitemizden daha
                      hızlı ve verimli hizmet alabilmeniz ve ziyaretçi
                      istatistikleri toplamak amaçlı çerez bilgilerinin
                      kullanılması, Hakkınızın tesisi doğrultusunda web sitemiz
                      üzerinde e belediye işlemlerinin yürütülmesi
                    </h5>

                    <h4>Kişisel Verilerinizin Saklanması</h4>

                    <p>
                      Yukarıda toplanma ve işlenme şartlarının izah edildiği
                      kişisel verileriniz, Nurhak Belediyesi nezdinde yer alan
                      veri tabanında ve sistemlerde “Teknik ve İdari Tedbirler
                      Kılavuzu” yönergeleri doğrultusunda gizli olarak
                      saklanacak, yasal zorunluluklar ve bu belgede belirtilen
                      düzenlemeler haricinde hiçbir şekilde üçüncü kişilerle
                      paylaşılmayacaktır. Nurhak Belediyesi, kişisel
                      verilerinizin barındığı sistemleri ve veri tabanlarını,
                      KVKK’ nın 12. maddesi gereği kişisel verilerin hukuka
                      aykırı olarak işlenmesini önlemekle, yetkisiz kişilerin
                      erişimlerini engellemekle, erişim yönetimi gibi yazılım
                      anlamında tedbirleri ve fiziksel güvenlik önlemleri
                      almakla mükelleftir. Kişisel verilerin yasal olmayan
                      yollarla başkaları tarafından elde edilmesinin öğrenilmesi
                      halinde durum derhal, yasal düzenlemeye uygun ve yazılı
                      olarak Kişisel Verileri Koruma Kurulu’na bildirilecektir.
                    </p>

                    <p>Kişisel Verilerinizin Aktarılması</p>

                    <p>
                      Kişisel verileriniz, yukarıda açıklanan amaçlarla KVKK’nın
                      8. ve 9. maddeleri ve ilgili düzenlemeler kapsamında,
                      kanunen sağlamakla yükümlü olduğumuz hizmetlerin ifası ve
                      kayıt altına alınması amaçlı, ilgili kurum ve kuruluşlara
                      aktarılmaktadır. Ayrıca veri aktarımı gereken durumlarda,
                      aktarılmasını gerektiren sebeple sınırlı olmak kaydıyla
                      yurt içindeki hizmetlerinden faydalanılan, bilgi
                      teknoloji, arşiv, matbaa, kart basımı, dağıtım firması
                      gibi hizmet sağlayıcılara ve iş birliği içerisinde
                      olduğumuz diğer üçüncü tüzel kişilere, belli gizlilik
                      anlaşması koşulları içerisinde aktarılmaktadır. Kişisel
                      verileriniz yurt dışına aktarılmamaktadır.
                    </p>

                    <p>Açık rıza beyanı bulunmayan durumlar</p>

                    <p>
                      Kanunlarda açıkça öngörülmesi, Fiili imkânsızlık nedeniyle
                      rızasını açıklayamayacak durumda bulunan veya rızasına
                      hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir
                      başkasının hayatı veya beden bütünlüğünün korunması için
                      zorunlu olması, Bir sözleşmenin kurulması veya ifasıyla
                      doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
                      taraflarına ait kişisel verilerin işlenmesinin gerekli
                      olması, Veri sorumlusunun hukuki yükümlülüğünü yerine
                      getirebilmesi için zorunlu olması, İlgili kişinin kendisi
                      tarafından alenileştirilmiş olması, Bir hakkın tesisi,
                      kullanılması veya korunması için veri işlemenin zorunlu
                      olması, Nurhak Belediyesi’nin hukuki yükümlülüğünü yerine
                      getirebilmesi için zorunlu olduğu durumlarda,
                    </p>

                    <p>Kişisel Verilerinizin Saklanma Süreleri</p>

                    <p>
                      Kişisel verileriniz 6698 Sayılı Kişisel Verileri Koruma
                      Kanunu Hükümleri doğrultusunda, kişisel verinin türüne ve
                      işlenme sebeplerine göre kanunda ön görülen sürelerle
                      saklanmaktadır.
                    </p>

                    <p>Kişisel Verilerinizin Saklanma Süreleri</p>

                    <p>Kanun çerçevesinde kişisel verileriniz hakkında;</p>
                    <p>Tarafımızda hangi bilgilerin olduğu,</p>
                    <p>İşlenip işlenmediğini öğrenme,</p>
                    <p>İşlenmişse buna ilişkin bilgi talep etme;</p>
                    <p>
                      İşlenme amacını ve tarafımızca bu amaçlara uygun olarak
                      kullanıp kullanılmadığını öğrenme,
                    </p>
                    <p>
                      Yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri
                      öğrenme;
                    </p>
                    <p>
                      Eksik veya yanlış işlenmiş olması halinde düzeltilmesi
                      talep etme;
                    </p>
                    <p>
                      İşlenmesini gerektiren sebeplerin ortadan kalkması halinde
                      KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde
                      kişisel verilerin silinmesini, silinmesi veya yok
                      edilmesini ya da anonim hale getirilmesini talep etme;
                    </p>
                    <p>
                      Bu hallerde ya da düzeltme halinde bunların veri aktarılan
                      üçüncü kişilere bildirilmesini isteme;
                    </p>
                    <p>
                      İşlenen verilerin otomatik sistemler vasıtasıyla analiz
                      edilmesi suretiyle aleyhinize bir sonucun ortaya çıktığını
                      düşünüyorsanız bu duruma itiraz etme;
                    </p>
                    <p>
                      Kanuna aykırı olarak işlenmesi sebebiyle bir zarara
                      uğramanız halinde bu zararın giderilmesini talep etme
                      haklarınız mevcuttur.
                    </p>

                    <p>
                      İlgili kanun kapsamında, kişisel verileriniz ile ilgili
                      başvurularınızı aşağıdaki iletişim kanallarından biri ile,
                      aşağıdaki linkteki başvuru formunu doldurarak, tarafımıza
                      iletmeniz durumunda, Kanunu’nun 13. maddesine uygun olarak
                      başvuru taleplerini talebin niteliğine göre en geç 30
                      (otuz) gün içinde yazılı olarak tarafınıza geri dönüş
                      sağlanacaktır.
                    </p>

                    <p>
                      Talep edilen işlemin maliyet gerektirmesi halinde, Kişisel
                      Verilerin Korunması Kurulu tarafından belirlenen tarife
                      uygulanacaktır. Talebin reddedilmesi halinde, red
                      nedeni/nedenleri yazılı olarak veya elektronik ortamda
                      gerekçelendirilecektir.
                    </p>

                    <p>KVKK Başvuru İletişim Kanallarımız:</p>

                    <p>
                      KVKK’nın 13/1 maddesi uyarınca, yukarıda belirtilen
                      haklarınızı kullanmak ve ilgili taleplerinizi yazılı
                      olarak veya Kişisel Verileri Koruma Kurulu’nun belirlediği
                      diğer yöntemlerle Belediyemize iletebilirsiniz. KVKK’nın
                      11. Maddesi kapsamında yapacağınız yazılı başvuru
                      kanalları aşağıda yer almaktadır.
                    </p>

                    <p>
                      kvkk@nurhak.bel.tr adresimizin ekinde kimlik teyidi
                      sağlayabileceğimiz, resmi belge örneği olan e-posta yolu
                      ile,
                    </p>
                    <p>
                      0 (344) 471 21 11 numaralı çağrı merkezi aracılığı ile,
                    </p>
                    <p>
                      Belediyemizin açık adresi olan Seyitaliler Mahallesi
                      Atatürk Bulvarı No:81 Nurhak/KAHRAMANMARAŞ adresimize
                      kimliğiniz ile bizzat gelerek veya iadeli taahhütlü mektup
                      yolu ile başvuru yapılmalıdır.
                    </p>
                  </div>
                </div>

                <div class="share-post">
                  <strong
                    >{{ Belediyebilgisi.belediyeadi }} Sosyal Medya
                    Hesapları</strong
                  >

                  <ul class="links clearfix">
                    <li class="facebook">
                      <a :href="Belediyebilgisi.ilceadi" target="_blank">
                        <span class="icon fab fa-facebook-f"></span>
                        <span class="txt">Facebook</span>
                      </a>
                    </li>

                    <li class="twitter">
                      <a :href="Belediyebilgisi.ilceadi" target="_blank">
                        <span class="icon fab fa-twitter"></span>
                        <span class="txt">Twiter</span>
                      </a>
                    </li>

                    <li class="linkedin">
                      <a :href="Belediyebilgisi.ilceadi" target="_blank">
                        <span class="icon fab fa-linkedin-in"></span>
                        <span class="txt">Linkedin</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--Sidebar Side-->

          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
export default {
  name: "Tarihce",
  data() {
    return {
      Belediyebilgisi: {},
      Ilce: {},
      loadkont: false,
    };
  },
  components: {
    Sidebar,
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Ilce");
    await this.$store.dispatch("vericekme", "Genel");
    this.Ilce = this.$store.getters.getIlce;
    this.Belediyebilgisi = this.$store.getters.getBelgenel;
    this.loadkont = true;
  },
};
</script>