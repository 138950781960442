<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
           <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Başkan Detay</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="main-image-box">
                    <figure class="image">
                      <img :src="BaskanBilgisi.foto" alt />
                    </figure>
                  </div>

                  <h2>{{BaskanBilgisi.adi}}</h2>
                  <h4>{{BaskanBilgisi.adi}}</h4>

                  <p style="white-space: pre-line;">{{BaskanBilgisi.bilgi}}</p>
                </div>
              </div>
            </div>
          </div>

          <!--Sidebar Side-->

          <Sidebar/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from '@/views/Sidebar'
import firebase from 'firebase/app'
export default {
  name: "Mesaj",
  data(){
      return{
          BaskanBilgisi:{},
          loadkont:false
      }
  },
  components:{
      Sidebar
  },
  async created(){
      const ID=this.$route.params.baskanId;
     await firebase.firestore().collection('Baskanlarimiz').doc(ID).get()
      .then((result) => {
          const baskan=result.data();
          this.BaskanBilgisi=baskan;
      }).catch((err) => {
          console.log('baskanhata'+err);
      });
      this.loadkont=true;
  }
};
</script>